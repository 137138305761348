import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header"href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/es/nuestros-productos/active-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/active-protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/active-protect/">IT</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_ProtectCool.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/Protect_Cool_960x405px_top.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      Mousse Solar Refrescante <br />
                      Protect &amp; Cool<sup>®</sup> de Piz Buin
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Refrescante protección para piel</h3>
                      <p>
                        Consigue una protección y una agradable sensación de
                        frescor incluso los días más calurosos. Mousse Solar
                        Refrescante Protect &amp; Cool<sup>®</sup> de Piz Buin
                        proporciona una efectiva e inmediata protección contra
                        los rayos UVA / UVB con una sensacional y refrescante
                        espuma de fácil absorción, dejando tu piel fresca y con
                        una sensación a verano…. como si no llevaras protector
                        solar!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="mousse-solar-refrescante" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2ad9e4d77e0452083037617cf04799ea_f368.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>Mousse Solar Refrescante</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Esta Mousse Solar Refrescante proporciona una
                          inmediata y agradable sensación de frescor helado y no
                          contiene alcohol para evitar resecar la piel.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Su innovadora fórmula hidratante de textura no grasa
                            y ligera de fácil aplicación, se absorbe rápidamente
                            sin dejar marcas blancas.{" "}
                          </p>
                          <div> </div>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Utilizar una cantidad insuficiente
                          reduce significativamente el nivel de protección. Re
                          aplicar con frecuencia, especialmente después de
                          transpirar, nadar o secarse con una toalla. Evitar el
                          sol de mediodía y no permanecer mucho tiempo al sol,
                          incluso cuando se use protección solar. Mantener a los
                          bebés y a los niños alejados de la luz solar directa.
                          Para su uso facial, aplicar primero el producto en la
                          mano.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/es/nuestros-productos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/es/nuestros-productos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/es/nuestros-productos/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      href="/es/nuestros-productos/tan-protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/es/nuestros-productos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/after-sun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/galeria/" target="_self">
                      Galeria
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Lee más');\n} else {\nbox.slideDown();\n$(this).text('Leer menos');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
